import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import CustomLoader from "./shared-components/Loader";
import PrivilegeNotFound from "./shared-components/PrivilegeNotFound";
import Layout from "./layout";
import { useDispatch, useSelector } from "react-redux";
import { StorCurrentLocation, currentLocationStorage } from "./common.actions";
import { azureAdFetchCode, cacheManager, clearAzureAdAuthCodeAction, extractValueFromUrl, isReactAppAuthorized, isTokenExistInCookies } from "./helpers/util-common";
import "bootstrap/dist/css/bootstrap.min.css";
import "astm-ux-design-system/dist/bundle.css";
import "./assets/themes/main.scss";
import { FeatureFlagProvider } from "./provider/flagFeature";
import loginActions from "./models/login/login.actions";
import { initiateAzureRequestAction } from "./common.actions";
import { logger, LOG_TYPE } from "./helpers/dataDog.logger";
import Alert from "./shared-components/Alert";
import AlertDissmissable from "./shared-components/AlertDissmissable";
export var App = function (_a) {
    var history = _a.history;
    var dispatch = useDispatch();
    var authenticateByAzureAD = function (callback) {
        loginActions.authenticateByAzureADAction(dispatch, callback);
    };
    var authByCookiesToken = function (inputDTO, callback) {
        loginActions.authByCookiesTokenAction(dispatch, inputDTO, callback);
    };
    var initiateAzureRequest = function (code, sessionState, authState, cb) { return initiateAzureRequestAction(code, sessionState, authState, cb, dispatch); };
    var checkSessionInfo = function (callback) {
        loginActions.checkSessionInfo(dispatch, callback);
    };
    var props = useSelector(function (state) {
        return {
            showToastMessage: state.commonReducer.showToastMessage,
            loading: state.commonReducer.loading,
            content: _.get(state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage], state.commonReducer.code),
            showErrorPopup: state.commonReducer.showErrorPopup,
            errorMessages: state.commonReducer.errorMessages,
            isShowConfirmationPopup: state.commonReducer.isShowConfirmationPopup,
            showAlertPopup: state.commonReducer.showAlertPopup,
            alertMessage: state.commonReducer.alertMessage,
            userPermission: state.commonReducer.userPermission,
            miniLoader: state.commonReducer.miniLoader,
            isSafariAutoFillInfoVisible: state.commonReducer.isSafariAutoFillInfoVisible,
            isDocumentInfo: state.commonReducer.isDocumentInfo,
            message: state.commonReducer.message,
            uniqueRequestId: state.commonReducer.uniqueRequestId,
            dismissibleMessage: state.commonReducer.dismissibleMessage,
            history: history,
            showSuccessToastMesage: state.commonReducer.showSuccessToastMesage,
            showDismissibleToastMessage: state.commonReducer.showDismissibleToastMessage,
        };
    });
    var _b = useState(process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true"), isLoginByAzureAD = _b[0], setisLoginByAzureAD = _b[1];
    var _c = useState(false), isUserHavePrivileges = _c[0], setisUserHavePrivileges = _c[1];
    var _d = useState(0), validateUserSession = _d[0], setValidateUserSession = _d[1];
    var _e = useState(false), hasServerSession = _e[0], setHasServerSession = _e[1];
    useEffect(function () {
        if (validateUserSession == 0)
            return;
        validateAzureCodeOnUrl();
    }, [validateUserSession]);
    useEffect(function () {
        checkSessionInfo(function (response) {
            var keyStatus = false;
            if (response.success) {
                if (!response.data.hasDMSAppKey) {
                    cacheManager.clearSession();
                }
                else {
                    keyStatus = true;
                }
                setHasServerSession(keyStatus);
            }
            setValidateUserSession(1);
        });
    }, []);
    // Validate Azure AD request for AD-TOKEN
    var validateAzureCodeOnUrl = function () {
        //const { PAGE_TYPE, USER_PRIVILEGE } = useSelector;
        var locationObject = currentLocationStorage();
        var isLoginWithAD = isLoginByAzureAD;
        if (isLoginWithAD && !isReactAppAuthorized()) {
            if (isTokenExistInCookies()) {
                // RNE Application type Id, For get Redis Key.
                var applicationTypeId = 1;
                authByCookiesToken({ appId: applicationTypeId }, function (isSuccess, code) {
                    if (isSuccess) {
                        setHasServerSession(true);
                        window.history.pushState({}, "", "/ballot");
                        window.location.reload();
                    }
                    else if (!isSuccess && code === "1013") {
                        // Call here for No Privilege Found
                        setisUserHavePrivileges(true);
                        // window.location.reload();
                    }
                    else {
                        // Else clear session.
                        window.location.assign(clearAzureAdAuthCodeAction());
                    }
                });
            }
            else {
                var code = extractValueFromUrl(window.location.href, "code");
                var sessionState = extractValueFromUrl(window.location.href, "session_state");
                var authState = extractValueFromUrl(window.location.href, "state");
                if (code && sessionState && authState) {
                    // TODO : Azure Init Call for create browser session.
                    initiateAzureRequest(code, sessionState, authState, function (isSuccessAzureInitRequest) {
                        if (isSuccessAzureInitRequest) {
                            // TODO : Auth Code Login Call while get success azure init call.
                            authenticateByAzureAD(function (isSuccessAzureAuthRequest, resCode) {
                                if (isSuccessAzureAuthRequest) {
                                    setHasServerSession(true);
                                    if (locationObject.isLocation) {
                                        props.history.push(locationObject.location);
                                    }
                                    else {
                                        StorCurrentLocation(dispatch, "/ballot");
                                        window.history.pushState({}, "", "/ballot");
                                        window.location.reload();
                                    }
                                }
                                else if (!isSuccessAzureAuthRequest && resCode === "1013") {
                                    // Call here for No Privilege Found
                                    setisUserHavePrivileges(true);
                                }
                                else {
                                    // Else clear session.
                                    window.location.assign(clearAzureAdAuthCodeAction());
                                }
                            });
                        }
                        else {
                            // Call here for No Privilege Found
                            setisUserHavePrivileges(true);
                        }
                    });
                } // Redirect for get token from Azure AD
                else {
                    window.location.assign(azureAdFetchCode());
                }
            }
        }
    };
    logger("Initializing App...", {}, LOG_TYPE.error, "");
    var _f = useState(false), scrolled = _f[0], setScrolled = _f[1];
    useEffect(function () {
        var handleScroll = function () {
            if (window.scrollY > 150) {
                setScrolled(true);
            }
            else {
                setScrolled(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return function () {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (_jsxs("div", { "data-testid": "app-id", className: scrolled ? "scrolled-class" : "", children: [(props.loading || props.miniLoader) && _jsx(CustomLoader, {}), props.showSuccessToastMesage && _jsx(Alert, { content: props.content, message: props.message, fileName: "", fileNameList: [] }), _jsxs("div", { className: "dismiss-toaster-wrapper", children: [props.showToastMessage && _jsx(Alert, { content: props.content, message: props.message, uniqueRequestId: props.uniqueRequestId, fileName: "", fileNameList: [] }), props.showDismissibleToastMessage && _jsx(AlertDissmissable, { content: props.content, message: props.dismissibleMessage })] }), isUserHavePrivileges ? (_jsx(PrivilegeNotFound, {})) : (hasServerSession && (_jsx(FeatureFlagProvider, { children: _jsx(Layout, {}) })))] }));
};
export default App;
