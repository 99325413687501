// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-color {
  color: #232f3a !important;
  background-color: #232f3a !important;
  border-color: #fdc92f !important;
}

.custom-link {
  text-decoration: none !important;
  color: white !important;
}

.w-20 {
  width: 20% !important;
}

.sub-title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.export-button {
  background: rgb(0, 93, 164) !important;
  border-radius: 3px;
  height: 38px;
  width: 161px;
  padding: 0 !important;
}

.btn.waring-button {
  background: #fdc92f;
  border-radius: 3px;
  color: #232f3a;
  height: 38px;
  width: 161px;
  padding: 0 !important;
  border: 0;
}
.btn.waring-button:hover {
  background: #e3b42a;
  color: #232f3a;
}

.export-text {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: normal;
  height: 18px;
  letter-spacing: 0px;
  line-height: 18px;
  text-align: center;
  width: 105px;
  width: 100%;
}
.export-text i {
  height: 13px;
  width: 9px;
}
.export-text span {
  margin-left: 10px;
}

.advanced-search {
  position: absolute;
  background: #fff;
  padding: 20px;
  border: 1px solid rgb(221, 221, 221);
  z-index: 9;
  top: 51px;
  width: 470px;
  border-radius: 4px;
}

#addBallotModal input.rbt-input-main.form-control.rbt-input.form-control-sm {
  height: 38px;
  font-size: 1rem;
  border: 1px solid #ced4da;
}
#addBallotModal .form-group.custom-radio-block > div {
  display: flex;
  align-items: center;
  margin-right: 10px;
  height: 30px;
}
#addBallotModal .form-group.custom-radio-block > div .custom-input-box {
  margin-right: 24px;
}
#addBallotModal .form-group.custom-radio-block > div .custom-input-box:last-child {
  margin-right: 0px;
}
#addBallotModal .form-group.custom-radio-block > div .custom-input-box .astm-custom-radio.form-check input {
  display: none;
}
#addBallotModal .form-group.custom-radio-block > div .custom-input-box .astm-custom-radio {
  margin: 0;
}
#addBallotModal .form-group.custom-radio-block > div .custom-input-box .astm-custom-radio .form-check-label {
  margin: 0;
  position: relative;
  top: 3px;
}
#addBallotModal .form-group.custom-radio-block > div .custom-input-box .astm-custom-radio .form-check-label::before {
  content: "";
  background: #fff;
  width: 5px;
  height: 5px;
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  left: -16px;
  top: 5px;
  z-index: 9;
}
#addBallotModal .form-group.custom-radio-block > div .custom-input-box .astm-custom-radio .form-check-label::after {
  content: "";
  background: #fff;
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid #adb5bd;
  position: absolute;
  top: 0;
  left: -21px;
}
#addBallotModal .form-group.custom-radio-block > div .custom-input-box .astm-custom-radio.active.form-check label::after {
  content: "";
  display: inline-block;
  border: 1px solid #005da4;
  background-color: #005da4;
  position: absolute;
  z-index: 1;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  left: -21px;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Ballot/_ballotScreen.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,oCAAA;EACA,gCAAA;AACF;;AACA;EACE,gCAAA;EAEA,uBAAA;AACF;;AACA;EACE,qBAAA;AAEF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAGF;;AAAA;EACE,sCAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,qBAAA;AAGF;;AADA;EACE,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,YAAA;EACA,qBAAA;EACA,SAAA;AAIF;AAFE;EACE,mBAAA;EACA,cAAA;AAIJ;;AADA;EACE,yBAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AAIF;AAHE;EACE,YAAA;EACA,UAAA;AAKJ;AAHE;EACE,iBAAA;AAKJ;;AADA;EACE,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,oCAAA;EACA,UAAA;EACA,SAAA;EACA,YAAA;EACA,kBAAA;AAIF;;AAAE;EACE,YAAA;EACA,eAAA;EACA,yBAAA;AAGJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;AAEJ;AAAI;EACE,kBAAA;AAEN;AADM;EACE,iBAAA;AAGR;AAAQ;EACE,aAAA;AAEV;AAEM;EACE,SAAA;AAAR;AACQ;EACE,SAAA;EACA,kBAAA;EACA,QAAA;AACV;AACU;EACE,WAAA;EACA,gBAAA;EACA,UAAA;EACA,WAAA;EACA,qBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;EACA,UAAA;AACZ;AACU;EACE,WAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;AACZ;AAIM;EACE,WAAA;EACA,qBAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;EACA,MAAA;AAFR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
