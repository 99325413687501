var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import lodash from 'lodash';
import { logout, setCurrentLocationStorage, setMessage } from './common.actions';
import getApiUrl from './helpers/api-urls';
import * as utilCommon from './helpers/util-common';
import { cookieKeysEnum } from './models/common.models';
// Exclude Internal Requests for bearer token
var excludeRequests = [
    getApiUrl('login', 'getAzureADRefreshToken'),
    getApiUrl('login', 'login'),
    getApiUrl('login', 'authenticateByAzureAD')
];
var isRefreshing = false;
var isLogoutRequest = false;
var tokenSubscribers = [];
var setupInterceptors = function (store) {
    // All Interceptor Request
    axios.interceptors.request.use(function (config) {
        if (!config.url.includes('specbuilder.astm.org')) {
            var generateUUID = utilCommon.generateUUID();
            // Set app request token.
            setAuthorizationOnApp(config);
            // Set Unique UDID for each request.
            if (utilCommon.cacheManager.getItem('uniqueRequestIdForApis') === null) {
                utilCommon.cacheManager.setItem('uniqueRequestIdForApis', JSON.stringify({}));
            }
            var storageUUIDValues = JSON.parse(utilCommon.cacheManager.getItem('uniqueRequestIdForApis'));
            var newStorageUUIDValues = __assign({}, storageUUIDValues);
            newStorageUUIDValues[config.url] = generateUUID;
            utilCommon.cacheManager.setItem('uniqueRequestIdForApis', JSON.stringify(newStorageUUIDValues));
            config.headers.uniquerequestid = generateUUID;
            // TODO : Check Hostname is localhost and set azure request header.
            if (utilCommon.isLocalhost()) {
                config.headers.AZURE_INIT_REQUEST = utilCommon.cookiesManager.getCookie(cookieKeysEnum.APP_SESSIONID, false);
            }
        }
        return config;
    });
    // All Interceptor Response
    axios.interceptors.response.use(function (response) {
        if (response && response.data) {
            // Validate Data is in String Format
            if (lodash.isString(response.data)) {
                // Parse JSON for security
                var updateData = response.data.replace(')]}', "");
                response.data = JSON.parse(updateData);
            }
            var msgCode = lodash.get(response.data, 'message');
            // Pass code in auto logout function.
            autoLogoutOnInvalidCodes(msgCode, store);
        }
        return response;
    }, function (error) {
        var config = error.config, _a = error.response, status = _a.status, message = _a.data.message;
        var originalRequest = config;
        if (status === 401 && process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
            var email = utilCommon.getCurrentUserEmail();
            // Validate if refresh token call has gone at once.
            if (!isRefreshing) {
                // TODO : Force Logout while no valid email id found.
                if (!email) {
                    autoLogoutOnInvalidCodes(status, store);
                    return false;
                }
                // If code will reach here,then new token will be updated.
                isRefreshing = true;
                getAzureADRefreshTokenAction({ "email": email }, function (tokenResult) {
                    if (tokenResult.status) {
                        isRefreshing = false;
                        onRrefreshedHandler(tokenResult.accessToken);
                        tokenSubscribers = [];
                    }
                    else {
                        // Validate Azure Ad Logout form app.
                        autoLogoutOnInvalidCodes(message, store);
                    }
                });
            }
            // TODO : Auto-Logout while refresh token call failer [401:Status, 1005:Message] => No Redis Found in Redis Cache.
            if (config.url === getApiUrl('login', 'getAzureADRefreshToken') && Number(message) === 1005) {
                autoLogoutOnInvalidCodes(message, store);
                return false;
            }
            // TODO : Re-Request  for All failer calls.
            return new Promise(function (resolve, reject) {
                subscribeToken(function (token) {
                    // Replace the expired token and retry
                    originalRequest.headers.Authorization = 'Bearer ' + token;
                    resolve(axios(originalRequest));
                });
            });
        }
        return Promise.reject(error);
    });
};
// Refresh token call While got 401 (Un-Authorized) Status.
export var getAzureADRefreshTokenAction = function (inputDTO, callback) {
    var url = getApiUrl('login', 'getAzureADRefreshToken');
    axios.post(url, inputDTO).then(function (response) {
        if (lodash.get(response.data, 'success')) {
            callback({ status: true, accessToken: response.data.accessToken });
            utilCommon.setLoginDataToCacheManager(response, 'REFRESH'); // Add Refresh Token Details in LS.
        }
        else {
            callback({ status: false, accessToken: null });
            utilCommon.setLoginDataToCacheManager(response, 'DELETE'); // For delete Detials in LS.
        }
    });
};
// Cached all Http calls which one failed
export var subscribeToken = function (cb) {
    tokenSubscribers.push(cb);
};
// Refresh handler while all http calls failed
export var onRrefreshedHandler = function (token) {
    tokenSubscribers.map(function (cb) { return cb(token); });
};
// Auto Logout while found unauthorized request.
export var autoLogoutOnInvalidCodes = function (code, store) {
    // In case of Auto logout while getting code (1005) from server.
    if (code && Number(code) === 1005 && !isLogoutRequest) {
        isLogoutRequest = true;
        if (window.location.pathname != '/') {
            store.dispatch(setMessage(true, '401'));
            logout(store.dispatch);
            // Logout If token Unauthorized
            utilCommon.cacheManager.clearSession();
            utilCommon.cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
            setCurrentLocationStorage();
            setTimeout(function () { window.location.assign(utilCommon.clearAzureAdAuthCodeAction()); }, 100);
        }
    }
};
// Custom Logout While Get 401 on Error.
export var customLoginClearCache = function (status, store) {
    if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "false" && status === 401) {
        store.dispatch(setMessage(true, status));
        // Logout If token Unauthorized
        utilCommon.cacheManager.clearSession();
        if (window.location.pathname != '/') {
            store.dispatch(logout(store.dispatch));
        }
        return false;
    }
};
// Set Authorization token in all request.
export var setAuthorizationOnApp = function (config) {
    var token = null;
    if (utilCommon.isReactAppAuthorized() && excludeRequests.indexOf(config.url) === -1) {
        token = utilCommon.cacheManager.getItem(cookieKeysEnum.accessToken, false);
        var stringIndex = config.url.split("v1");
        utilCommon.openApiPathArray.indexOf(stringIndex[1]);
        if (utilCommon.openApiPathArray.indexOf(stringIndex[1]) === -1) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        config.headers['Is-Test-Mode'] = isTestUserLogin();
    }
    else if (!utilCommon.isReactAppAuthorized() && config.url.includes(utilCommon.openApiPathArray)) {
        config.headers['Is-Test-Mode'] = isTestUserLogin();
    }
};
export var isTestUserLogin = function () {
    if (utilCommon.cacheManager.getItem('userDetail')) {
        return JSON.parse(utilCommon.cacheManager.getItem('userDetail')).isTestRecord === true;
    }
    return false;
};
export default setupInterceptors;
