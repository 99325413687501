// Login
export var LOGIN = "LOGIN";
export var AZURE_LOGIN = "AZURE_LOGIN";
export var AZURE_REFRESH_TOKEN = "AZURE_REFRESH_TOKEN";
// Common
export var SET_MESSAGE = "SET_MESSAGE";
export var SET_LOADER = "SET_LOADER";
export var SHOW_ERROR_POPUP = "SHOW_ERROR_POPUP";
export var SHOW_ALERT_POPUP = "SHOW_ALERT_POPUP";
export var SET_MINI_LOADER = "SET_MINI_LOADER";
export var SHOW_NOT_FOUND_PAGE = "SHOW_NOT_FOUND_PAGE";
export var HIDE_NOT_FOUND_PAGE = "HIDE_NOT_FOUND_PAGE";
export var GET_APP_USER_LIST = "GET_APP_USER_LIST";
export var IS_SAFARI_AUTOFILL_VISISBLE = "IS_SAFARI_AUTOFILL_VISISBLE";
export var STORE_CURRENT_LOCATION = "STORE_CURRENT_LOCATION";
export var SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export var SHOW_DISMISSIBLE_MESSAGE = "SHOW_DISMISSIBLE_MESSAGE";
export var GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export var GET_US_STATE_LIST = "GET_US_STATE_LIST";
export var GET_CANADA_STATE_LIST = "GET_CANADA_STATE_LIST";
// Permission Action
export var ALLOW_PERMISSION_FOR_PAGE = "ALLOW_PERMISSION_FOR_PAGE";
//Export
export var EXPORT_BALLOTS_LIST = "EXPORT_BALLOTS_LIST";
//Global Search
export var SET_GLOBAL_SEARCH_TEXT = "SET_GLOBAL_SEARCH_TEXT";
export var GET_GLOBAL_SEARCH_TEXT = "GET_GLOBAL_SEARCH_TEXT";
//Advance Search
export var SET_BALLOT_ADVANCE_SEARCH = "SET_BALLOT_ADVANCE_SEARCH";
//Ballot Advance Search Form Data
export var SET_BALLOT_ADVANCE_FORM_DATA = "SET_BALLOT_ADVANCE_FORM_DATA";
//Ballot details data
export var SET_BALLOT_DETAIL_TAG = "SET_BALLOT_DETAIL_TAG";
export var SET_BALLOT_DETAILS = "SET_BALLOT_DETAILS";
//Ballot Status
export var SET_SELECTED_BALLOT_STATUS = "SET_SELECTED_BALLOT_STATUS";
export var GET_SELECTED_BALLOT_STATUS = "GET_SELECTED_BALLOT_STATUS";
//Ballot Status
export var SET_QUICK_SEARCH_SELECTED_USER = "SET_QUICK_SEARCH_SELECTED_USER";
export var GET_QUICK_SEARCH_SELECTED_USER = "GET_QUICK_SEARCH_SELECTED_USER";
//Ballot Level
export var SET_QUICK_SEARCH_SELECTED_LEVEL = "SET_QUICK_SEARCH_SELECTED_LEVEL";
export var GET_QUICK_SEARCH_SELECTED_LEVEL = "GET_QUICK_SEARCH_SELECTED_LEVEL";
