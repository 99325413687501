var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import logoImg from "../../assets/images/astm-internal.png";
import React from "react";
import lodash from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import NavigationMenu from "../NavigationMenu";
import { useDispatch } from "react-redux";
import { logout } from "../../common.actions";
import SearchBox from "../SearchBox";
export var HeaderMenu = function (props) {
    var dispatch = useDispatch();
    var applogout = function () {
        logout(dispatch);
    };
    return (_jsxs("header", { "data-testid": "headertest", children: [_jsx("div", { className: "container-fluid", children: _jsxs("div", { className: "headerWrap", children: [_jsx("div", { className: "logoWrap height-34", children: _jsx("a", { href: "", children: _jsx("img", { src: logoImg, alt: "ASTM Logo" }) }) }), _jsxs("div", { className: "top-right-section", children: [_jsx("div", { className: "searchWrap", children: _jsx(SearchBox, __assign({}, props, { ballotStatusOption: props.ballotStatusOption, onGlobalSearchSubmit: props.onGlobalSearchSubmit })) }), _jsx("div", { className: "headerFilterhWrap", children: _jsx("div", { className: "profileOptionMenu dropdown", children: _jsxs(Dropdown, { children: [_jsxs(Dropdown.Toggle, { variant: "success", id: "dropdown-basic", className: "btn astm-btn btn-primary yellow-btn", children: [_jsx("i", { className: "fas fa-user-circle astm-icon", style: { marginInlineEnd: 4 } }), props.userDetail ? lodash(props.userDetail).get("userName", "").toLocaleUpperCase() : ""] }), props.userDetail ? (_jsx(Dropdown.Menu, { children: _jsx(Dropdown.Item, { "data-testid": "logout-id", onClick: applogout, children: "Logout" }) })) : ("")] }) }) })] })] }) }), _jsx(NavigationMenu, { history: props.history })] }));
};
export default HeaderMenu;
