import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import CustomLoader from "../shared-components/Loader";
import { customeRoutes } from "./lazy-load";
import { LEFT_NAVIGATION_PAGE_URL } from "../models/navigation.models";
import InvalidUrl from "../shared-components/invalid-url/InvalidUrl";
var Navigation = function (props) {
    var location = useLocation();
    var _a = useState(false), invalidUrl = _a[0], setInvalidUrl = _a[1];
    // this code will be removed when we add the permissions
    useEffect(function () {
        var validPaths = Object.values(LEFT_NAVIGATION_PAGE_URL);
        setInvalidUrl(validateUrl(validPaths));
    }, [location]);
    var validateUrl = function (validPaths) {
        var _a;
        if ((_a = location === null || location === void 0 ? void 0 : location.pathname) === null || _a === void 0 ? void 0 : _a.length) {
            var path = location.pathname.split("/")[1];
            return validPaths.indexOf("/" + path) === -1;
        }
    };
    return (_jsx("div", { className: "btapp-container container-fluid", children: _jsx(Suspense, { fallback: _jsx(CustomLoader, {}), children: invalidUrl ? (_jsx(InvalidUrl, {})) : (_jsxs(Routes, { children: [_jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.BALLOTS, element: _jsx(customeRoutes.Ballots, { ballotStatusOption: props.ballotStatusOption, quickSearchSelectedUser: props.quickSearchSelectedUser, updateQuickSearchUser: props.updateQuickSearchUser, currentAction: props.currentAction, setCurrentAction: props.setCurrentAction }) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.DASHBOARD, element: _jsx(customeRoutes.DashBoard, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.BALLOTDETAILS + "/:ballotId", element: _jsx(customeRoutes.BallotDetails, {}) })] })) }) }));
};
export default Navigation;
