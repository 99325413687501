var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { isReactAppAuthorized, cacheManager, clearAzureAdAuthCodeAction } from "../helpers/util-common";
import { SHOW_NOT_FOUND_PAGE, HIDE_NOT_FOUND_PAGE } from "../action-type";
import { useDispatch, useSelector } from "react-redux";
import { validateNavigationFromUrl } from "../helpers/util-common";
import { getActiveCommitteesAction, getAllBallotStatusAction, getBallotDocumentsListAction } from "../redux/action/ballots";
import "./_layoutScreen.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FeatureFlag } from "../provider/flagFeature";
import { isEmpty, orderBy, uniq } from "lodash";
import HeaderMenu from "../shared-components/Header/header";
import Breadcrumb from "../shared-components/breadcrumb";
import Navigation from "../navigation/navigation";
import * as commonActions from "../common.actions";
import { logger, LOG_TYPE } from "../helpers/dataDog.logger";
export var Layout = function () {
    var _a;
    var history = useLocation();
    var navigate = useNavigate();
    var featureData = (_a = useContext(FeatureFlag)) === null || _a === void 0 ? void 0 : _a.FlagFeaturesData;
    var dispatch = useDispatch();
    var _b = useState([]), committeeData = _b[0], setCommitteeData = _b[1];
    var _c = useState(''), currentAction = _c[0], setCurrentAction = _c[1];
    var _d = useState([]), ballotDesignationData = _d[0], setBallotDesignationData = _d[1];
    var _e = useState([{ text: "All", value: "All", key: "all" }]), ballotStatusOption = _e[0], setBallotStatusOption = _e[1];
    //  this will be required in future
    // const [showAccessDenied, setShowAccessDenied] = useState(false);
    useEffect(function () {
        getCommitteeList();
        loadBallotData();
        getAllBallotStatus();
    }, []);
    // This code will be used when the permissions are added in the code
    var validateBreadcrumbsUrls = function () {
        var currentPath = history.pathname;
        // Get URL Details
        if (currentPath == "/" && isReactAppAuthorized()) {
            window.history.pushState({}, "", "/ballot");
            window.location.reload();
        }
        var urlDetails = validateNavigationFromUrl(currentPath);
        if (!urlDetails) {
            dispatch({ type: SHOW_NOT_FOUND_PAGE }); // IF Routes Not Matched
        }
        else {
            dispatch({ type: HIDE_NOT_FOUND_PAGE });
        }
    };
    useEffect(function () {
        validateBreadcrumbsUrls();
    }, [window.location.pathname]);
    var props = useSelector(function (state) {
        return {
            currentLocation: state.commonReducer.currentRequest,
            documentStatusByCategory: state.commonReducer.documentStatusByCategory,
            showNotFoundPage: state.commonReducer.showNotFoundPage,
            ballotAdvanceSearchFormData: state.commonReducer.ballotAdvanceSearchFormData,
            quickSearchSelectedUser: state.commonReducer.quickSearchSelectedUser,
        };
    });
    var isUserAuthorized = isReactAppAuthorized();
    var getCommitteeList = function () {
        getActiveCommitteesAction(dispatch, function (response) {
            if (response && response.length > 0) {
                var CommitteeList = uniq(response.map(function (item) { return item.Designation; }));
                setCommitteeData(CommitteeList);
            }
        });
    };
    var loadBallotData = function () {
        var data = {
            pageNumber: 1,
            pageSize: -1,
            sortColumnWithOrder: { ModifiedDate: 2 },
        };
        getBallotDocumentsListAction(dispatch, data, function (response) {
            if (!isEmpty(response)) {
                var sortedBallotDesignationData = orderBy(response.Data, ["BallotDesignation"], ["asc"]);
                var ballotDesignationList = uniq(sortedBallotDesignationData.map(function (item) { return item.BallotDesignation; }));
                setBallotDesignationData(ballotDesignationList);
            }
        });
    };
    var onGlobalSearchSubmit = function (currentSearchOption, currentSearchText) {
        if (window.location.pathname !== "/ballot") {
            navigate("/ballot");
        }
        setCurrentAction('global');
        dispatch(commonActions.setGlobalSearchText(currentSearchText));
    };
    var onAdvanceSearchSubmit = function (formData, type) {
        if (window.location.pathname !== "/ballot") {
            navigate("/ballot");
        }
        var selectedBallotStatus = (formData === null || formData === void 0 ? void 0 : formData.selectedBallotStatus) || "All";
        setCurrentAction('advance');
        dispatch(commonActions.setQuickSearchSelectedBallotStatus(selectedBallotStatus));
        dispatch(commonActions.setBallotAdvanceSearch(formData));
    };
    var setAdvBallotSearchFormData = function (formData) {
        dispatch(commonActions.setAdvBallotSearchFormData(formData));
    };
    logger("App started!", {}, LOG_TYPE.error, "Rendering layout");
    var userDetail = cacheManager.getItem("userDetail") && JSON.parse(cacheManager.getItem("userDetail"));
    if (!isUserAuthorized && !userDetail) {
        setTimeout(function () {
            window.location.assign(clearAzureAdAuthCodeAction());
        }, 500);
    }
    var getAllBallotStatus = function () {
        getAllBallotStatusAction(dispatch, function (response) {
            if (response && response.length > 0) {
                var mappedOptions = response.map(function (item) { return ({
                    text: item.BallotStatus,
                    value: item.BallotStatus.toString(),
                    key: item.BallotStatus.toLowerCase(),
                }); });
                setBallotStatusOption(__spreadArray([{ text: "All", value: "All", key: "all" }], mappedOptions, true));
            }
        });
    };
    var updateQuickSearchUser = function () {
        dispatch(commonActions.setQuickSearchSelectedUser("akumar"));
    };
    return (_jsx(_Fragment, { children: isUserAuthorized ? (_jsxs("div", { className: "homePage", "data-testid": "layout_id", children: [userDetail && _jsx(HeaderMenu, __assign({}, props, { committeeData: committeeData, ballotDesignationData: ballotDesignationData, userDetail: userDetail, onGlobalSearchSubmit: onGlobalSearchSubmit, onAdvanceSearchSubmit: onAdvanceSearchSubmit, setAdvBallotSearchFormData: setAdvBallotSearchFormData, ballotStatusOption: ballotStatusOption })), (featureData === null || featureData === void 0 ? void 0 : featureData.BTAppFeature) ? (_jsxs(_Fragment, { children: [_jsx(Breadcrumb, {}), _jsx(Navigation, { isUserAuthorized: isUserAuthorized, ballotStatusOption: ballotStatusOption, quickSearchSelectedUser: props.quickSearchSelectedUser, updateQuickSearchUser: updateQuickSearchUser, currentAction: currentAction, setCurrentAction: setCurrentAction })] })) : null] })) : ("") }));
};
export default Layout;
